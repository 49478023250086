<template>
  <section>
    <breadcrumb :title="$t('nomenclators')" :subtitle="$t('municipalities')"/>
    <div class="card px-10 pb-30 rounded-10">
      <div class="columns filters">
        <div class="column is-flex is-align-items-center">
          <div>
            <b-field>
              <b-input :placeholder="$t('search')"
                       custom-class="search-filter"
                       type="search"
                       icon="magnify"
                       icon-clickable
              >
              </b-input>
            </b-field>
          </div>
          <div class="ml-15">
            <b-icon type="is-pink-light" size="is-medium" icon="close-circle-outline"
                    class="mx-4 cursor-pointer"></b-icon>
          </div>
          <div>
            <b-field class="ml-30" custom-class="active-btn">
              <b-switch :value="false"
                        v-model="order"
                        :left-label="true"
                        size="is-medium"
                        type="is-green-success">
                {{ $t('order_province') }}
              </b-switch>
            </b-field>
          </div>
        </div>
      </div>
    </div>

    <section class="pt-40">
      <b-table
          :data="desserts"
          ref="table"
          :paginated="true"
          :current-page="currentPage"
          :per-page="itemsPerPage"
      >

        <b-table-column field="id" :label="$t('id')" width="40" numeric v-slot="props">
        <span class="col id">
           #{{ props.row.id }}
        </span>
        </b-table-column>
        <b-table-column field="name" :label="$t('name')" sortable v-slot="props">
            <span class="col name">
              {{ props.row.name }}
            </span>
        </b-table-column>

        <b-table-column field="code" :label="$t('code')" sortable v-slot="props">
       <span class="common">
         {{ props.row.adminCode }}
       </span>
        </b-table-column>

        <b-table-column field="province" :label="$t('province')" sortable v-slot="props">
          <b-tag type="is-tag-success success" rounded>
            <span class="text-status pending"> {{ props.row.proviceName }}</span>
          </b-tag>
        </b-table-column>


        <b-table-column field="exchange_distributions" :label="$t('exchange_distributions')" sortable v-slot="props">
        <span class="col common">
           {{ props.row.distributionCup }}
        </span>
        </b-table-column>

        <b-table-column field="exchange_rate" :label="$t('created')" sortable v-slot="props">
        <span class="col common">
           {{ formatYYYYMMDD(props.row.createdAt) }}
        </span>
        </b-table-column>

        <b-table-column field="exchange_rate" :label="$t('updated')" sortable v-slot="props">
        <span class="col common">
           {{ formatYYYYMMDD(props.row.updatedAt) }}
        </span>
        </b-table-column>


        <b-table-column :label="$t('actions')" v-slot="props">
          <div class="is-flex">
            <div @click="editMunicipality(props.row)">
              <b-icon type="is-green-accent" icon="pencil" class="mx-4 cursor-pointer"></b-icon>
            </div>
          </div>

        </b-table-column>
        <template #pagination>
          <b-pagination
              :total="desserts.length"
              v-model="currentPage"
              :range-before="3"
              :range-after="1"
              order="is-right"
              @change="saveCurrentPage"
              :per-page="itemsPerPage"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page">
          </b-pagination>
        </template>
      </b-table>
      <paginate v-if="false" :count="totalRecords" :per_page="itemsPerPage" :current-page="currentPage"/>
    </section>
    <b-modal
        v-model="isModalChangeMunicipalityActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-label="Example Modal"
        aria-modal>
      <template #default="props">
        <modals-edit-municipality :municipality-id="municipalityId" @reload="reload"
                                  @close="props.close"></modals-edit-municipality>
      </template>
    </b-modal>
  </section>

</template>

<script>
import Breadcrumb from "@/components/structure/breadcrumb";
import Paginate from "@/components/list/paginate";
import {mapActions} from "vuex";
import ModalsEditMunicipality from "@/components/modals/modalsEditMunicipality";
import {exitsKey, getStorage, saveStorage} from "@/utils";

export default {
  name: "MunicipalityComponent",
  components: {ModalsEditMunicipality, Paginate, Breadcrumb},
  data() {
    return {
      isModalChangeMunicipalityActive: false,
      municipalityId: null,
      order: false,
      propsModals: {},
      desserts: [],
      itemsPerPage: 10,
      currentPage: 1,
      totalPages: 0,
      totalRecords: 0,
    }
  },
  methods: {
    ...mapActions({
      fetchMunicipalities: 'FETCH_CUBAN_CITY'
    }),
    reload() {
      window.location.reload()
    },
    editMunicipality(props) {
      this.municipalityId = props

      this.isModalChangeMunicipalityActive = !this.isModalChangeMunicipalityActive
    },
    saveCurrentPage() {
      saveStorage('currentPageMunicipality', this.currentPage, false)
    },
    formatYYYYMMDD(value) {
      const date = new Date(value)
      if (value && !isNaN(date)) {
        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = date.getDate()
        return year + '-' +
            ((month < 10 ? '0' : '') + month) + '-' +
            ((day < 10 ? '0' : '') + day)
      }
      return ''
    },
    orderByProvince() {
      this.fetchMunicipalities()
          .then(value => {
            this.desserts = this.order ? value : value.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          })
    }
  },
  watch: {
    order: function () {
      saveStorage('orderByProvince', this.order, false)
      this.orderByProvince()
    }
  },
  created() {
    this.fetchMunicipalities()
        .then(value => {
          let order = exitsKey('orderByProvince') ? getStorage('orderByProvince') : false
          this.order = order
          this.desserts = order ? value : value.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
          if (exitsKey('currentPageMunicipality')) {
            this.currentPage = Number(getStorage('currentPageMunicipality', false))
          }
        })
  }
}
</script>

<style scoped lang="scss">
.col {
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #0B2B46;

  &.id {
    font-weight: 900;
    font-size: 20px;
    text-align: center;
    color: #C1C1C1;
  }

  &.name {
    font-weight: bold;
    font-size: 16px;
    color: #666BAA;
  }

  &.common {
    font-weight: normal;
    font-size: 16px;
    color: #0B2B46;
  }
}

.text-status {
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;
  font-weight: bold;

  &.pending {
    color: #289BA4;
  }

}


</style>