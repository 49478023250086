<template>
  <div class="modal-card modal-form rounded-10">
    <section class="modal-card-body">
      <div class="content">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="title-new-modal">{{ $t('edit_municipality') }}</div>
          <button @click="closed" class="delete" aria-label="close"></button>
        </div>
        <section class="pl-20 pr-10 pt-15">
          <div class="columns is-flex is-justify-content-space-between is-align-items-center">
            <div class="column is-5">
              <b-field :label="`${$t('code')}*`">
                <b-input v-model="code" type="text" placeholder="" custom-class="full-input"></b-input>
              </b-field>
            </div>
            <div class="column is-5">
              <b-field :label="`${$t('exchange_distributions')}*`">
                <b-input v-model="exchange_distribution" type="text" placeholder="" custom-class="full-input"></b-input>
              </b-field>
            </div>
            <div class="column is-2">
              <button @click="save" class="button save is-primary mt-30">{{ $t('save') }}</button>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>

</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "modalsEditMunicipality",
  data() {
    return {
      code: null,
      exchange_distribution: null
    }
  },
  props: ['municipalityId'],
  methods: {
    ...mapActions({
      editMunicipality: 'EDIT_MUNICIPALITY'
    }),
    save() {
      let edit = {
        code: this.code,
        distributionCup: Number(this.exchange_distribution),
        id: this.municipalityId.objectId
      }
      this.editMunicipality(edit)
          .then(() => {
            this.closed()
          })
    },
    closed() {
      this.$emit('close')
      this.$emit('reload')
      window.location.reload()
    }
  },
  created() {
    this.code = this.municipalityId.adminCode
    this.exchange_distribution = this.municipalityId.distributionCup
  }
}
</script>

<style scoped>

</style>